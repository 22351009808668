/* outsourced to
https://github.com/kmpartner/style-files */





/* .bodyContainer {
  margin-top: 2rem;
}

.body300x65Container {
  width: 300px; 
  height: 65px;
  border: 0.5px solid gray; 
  display: flex; 
  justify-content: space-between;
  margin-bottom: 1rem;
}

.body300x65Text {
  width: 235px;
  height: 100%; 
  overflow:hidden; 
}

.body300x65TextContents {
  display:flex; 
  flex-direction:column; 
  justify-content: space-between; 
  padding:5px 0 0 10px;
}

.body300x65TextTitle {
  font-size: 16px; 
  font-weight:bold;
  text-align:center;
}

.body300x65TextDescription {
  font-size: 12px; 
  font-weight: bold;
  padding-top: 2px;
}

.body300x65Image {
  width: 65px;
  height:65px;
  object-fit: cover;
}

.body300x300Container {
  width: 300px; 
  margin-bottom: 1rem;
}

.body300x300Image {
  width: 300px;
  height: 300px;
  object-fit: cover;
}

.body300x300TextContainer {
  width: 300px;
  height: 60px;
  display: flex;
  flex-direction: column;
  border: 0.5px solid gray;
  border-radius: 6px;
  padding: 2px 10px 2px 10px;
  overflow: hidden;
}

.body300x300TextTitle {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

.body300x300TextDescription {
  font-size: 13px;
  font-weight: bold;
  padding-top: 4px;
}

.bodyInPostContainer {
  width: 100%;
  max-width: 40rem; 
  height: 150px; 
  border: 0.5px solid gray;
  border-radius: 6px;
  overflow: hidden;
}

.bodyInPostContents {
  display: flex;
}

.bodyInPostImageContainer {
  width: 150px;
  height: 150px;
  object-fit: cover;
}

.bodyInPostTextContainer {
  flex-grow: 2;
}

.bodyInPostTextTitle {
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0 10px 0;
}

.bodyInPostTextDescription {
  font-size: 16px;
  padding: 0 5px;
}

@media (min-width: 480px) {
  .bodyInPostTextTitle {
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    padding: 20px 0 10px 0;
  }
  
  .bodyInPostTextDescription {
    font-size: 22px;
    padding: 0 20px;
  }
} */




/* .body150x500Container {
  width: 150px;
  height: 500px;
  border: 0.5px solid gray;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.body150x500Text {
  width: 150px;
  height: 350px;
  overflow: hidden;
}

.body150x500TextContents {
  display: flex;
  flex-direction: column;
}

.body150x500TextTitle {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 40px 0px 0px 0px;
}

.body150x500TextDescription {
  font-size: 18px;
  text-align: left;
  padding: 40px 0px 0px 5px;
}

.body150x500Image {
  width: 150px;
  height: 150px;
  object-fit: cover;
} */


/* .body450x90Container {
  width: 450px;
  height: 90px;
  border: 0.5px solid gray;
  display: flex;
  justify-content: space-between;
}

.body450x90Text {
  width: 360px;
  height: 90px;
  overflow: hidden;
}

.body450x90TextContents {
  display: flex;
  flex-direction: column;
}

.body450x90TextTitle {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  padding: 5px 0px 0px 0px;
}

.body450x90TextDescription {
  font-size: 20px;
  text-align: left;
  padding: 2.5px 2.5px 2.5px 5px;
}

.body450x90Image {
  width: 90px;
  height: 90px;
  object-fit: cover;
} */




/* .body600x130Container {
  width: 600px;
  height: 130px;
  border: 0.5px solid gray;
  display: flex;
  justify-content: space-between;
}

.body600x130Text {
  width: 470px;
  height: 130px;
  overflow: hidden;
}

.body600x130TextContents {
  display: flex;
  flex-direction: column;
}

.body600x130TextTitle {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  padding: 10px 0px 0px 0px;
}

.body600x130TextDescription {
  font-size: 24px;
  text-align: left;
  padding: 10px 10px 2.5px 10px;
}

.body600x130Image {
  width: 130px;
  height: 130px;
  object-fit: cover;
} */




.previewContainer {
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
}

.updateImage {
  padding-top: 1.5rem;
}

.updateImageTitle {
  font-size: 1.25rem;
}

.updateImageContent {
  margin: 1rem;
}
