.post {
  margin: 1rem 0;
  border: 1px solid #3b0062;
  border: 0.5px solid #999;
  border-radius: 5px;
  padding: 0.5rem;
}

.post__meta {
  /* font-size: 1rem; */
  font-size: 0.8rem;
  color: #707070;
  margin: 0;
  margin-top: 1.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.post__title {
  font-size: 1.5rem;
  font-weight: bolder;
  /* margin: 1rem 0; */
  margin: 0.2rem 0;
  color: #3b0062;
  color: #999;
  color: var(--color);
  text-decoration: none;
}

.post__contentContainer {
  margin: 1rem 0rem
}

.post__content {
  color: var(--color);
  text-decoration: none;
}

.post__image {
  height: 15rem;
  width: 100%;
}

.post__actions {
  text-align: right;
}

.post__AuthorElement {
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.post__AuthorImageContainer {
  width: 4.5rem;
  height: 3rem;
  text-align: center;
  margin-right: 1rem;
}

.post__AuthorImageElement {
  max-width: 100%;
  max-height: 100%;
}

.post__AuthorName {
  /* margin-left: 1rem; */
}

.post__FavoriteButton {
  text-align: right;
}

.post__FollowUsersTitle {
  margin-top: 1rem;
  cursor: pointer;
}

@media (min-width: 768px) {
  .post {
    padding: 1rem;
    /* width: 40rem; */
    margin-left: auto;
    margin-right: auto;
  }
}
