.main-nav {
  height: 100%;
  padding: 0 1rem;
  display: flex;
  align-items: center;
}

.spacer {
  flex: 1;
}

.main-nav__items {
  list-style: none;
  padding: 0;
  margin: 0 1.5rem;
  display: none;
}

.name {
  color: white;
  margin-left: 1rem;
  cursor: pointer;
}

.userImage {
  margin-top: 0.25rem;
  margin-left: 1rem;
  border-radius: 0.1rem;
  cursor: pointer;
}

.createButton {
  font-size: 2rem;
  margin-left: 1rem;
  color: white;
  cursor: pointer;
}

.mainNavPageNotification {
  color: white;
  margin: 0 1rem;
  cursor: pointer;
}

@media (min-width: 768px) {
  .main-nav__items {
    display: flex;
  }
}
