.feedEditTimeItemsTitle {
  margin-top: 2rem;
  text-transform: uppercase;
}

.feedEditTimeItemsLabel {
  margin-top: 1rem;
  display: block;
}

.feedEditTimeItemsDateInput {
  height: 2rem;
  width: auto;
  min-width: 8rem;
  padding: 0.25rem;
}

.feedEditTimeItemsSelectedTime {
  font-weight: bold;
  margin-top: 0.5rem;
}