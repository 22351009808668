
.chargeRecordTable {
  font-size: 1rem;
}

.chargeRecordTable table {
  border-collapse: collapse;
  max-width: 100%;
  margin: 0 auto;
  /* border: 1px solid green; */
}

.chargeRecordTable tr {
  border: 0.25px solid gray;
}

.chargeRecordTable th {
  padding: 1rem 1rem;
  text-align: right;
}

.chargeRecordTable td {
  padding: 1rem 1rem;
  text-align: right;
}