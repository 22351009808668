.tableControlSearchAd {
  /* padding: 0.5rem; */
  margin-bottom: 1.5rem;
  width: 95%;
  /* max-width: 30rem; */
  /* font-size: 1.1rem; */
}

.tableControlSelect {
  padding: 0.5rem;
  margin-bottom: 1.5rem;
  width: 95%;
  /* max-width: 30rem; */
  font-size: 1.1rem;
}

.tableControlSelected {
  display: flex;
  gap: 1rem;
  font-size: 1.25rem;
}

@media (min-width: 768px) {
  .tableControlSelect {
    max-width: 30rem;
  }
  
  .tableControlSearchAd {
    max-width: 30rem;
  }
}