.userFilesSelectAdImageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;  

  border: 0.1px solid gray;
  border-radius: 4px;
  margin-bottom: 0.5rem;
  padding: 0.25rem;
}

.userFilesSelectAdImageDiv {
  width: 150px; 
  text-align: center;
}

.userFilesSelectAdImage {
  /* width: 125px;  */
  /* width: 30px; */
  /* height: 150px;
  width: 150px; */
  object-fit: cover;
  padding: 10px 10px;
  /* cursor: pointer; */
  /* height:"150px", 
  marginRight: "20px", cursor:"pointer" */
}

.userFilesSelectVideoContainer {
  position: relative;
  text-align: center;
  width: 150px;
}

.userFilesSelectVideo {
  position: absolute;

  height: 100px;
  width: 100px;
  /* object-fit: cover; */
  /* padding: 10px 10px; */
  /* cursor: pointer; */
}

.userFilesSelectVideoMark {
  background-color: rgba(0,0,0,0.01);
  color: gray;
  position: absolute;
  padding: 1rem;

  top: 10%;
  left: 1%;
  font-size: 2rem;
  /* cursor: pointer; */
}

.userFilesSelectClose {
  text-align: right;
}

.userFilesSelectCloseButton {
  font-size: 2rem;
  font-weight: bold;
  padding: 0.5rem;
  cursor: pointer;
}

@media (min-width: 768px) {
  .userFilesSelectAdImageContainer {
    flex-direction: row;
  }
}