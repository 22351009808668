.feedEdit__deleteImageElements {
  /* float: right; */
  text-align: right;
}
.feedEdit__deleteConfirm {
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.feedEdit__aboutMediaFile {
  font-size: 0.8rem;
  margin-bottom: 1rem;
}

.feedEdit__selectStyle {
  font-size: 1rem;
  margin-top: 0.25rem;
}

.feedEdit__sectionTitle {
  margin-top: 2rem;
  text-transform: uppercase;
}