.customerMethodListCard {
  width:95%;
  max-width: 40rem;
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 2px;
  box-shadow: 0 1px 4px rgb(0 0 0 / 26%);
}

.customerMethodListItem {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.customerMethodListButtons {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.customerMethodListButton {
  margin-left: 1rem;
}

.customerMethodListConfirm {
  text-align: center;
  margin: 4rem 0 2rem 0;
  font-size: 1.25rem;
}

.customerMethodListLoader {
  text-align: center;
}

.customerMethodListNoDefault {
  text-align: center;
  font-weight: bold;
  font-size: 1.25rem;
}