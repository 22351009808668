.areaControlTitle {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.areaControlInput {
  height: 2rem;
  padding: 0.25rem;
}

.areaControlRadius {
  font-weight: bold;
  margin-top: 0.5rem;
}