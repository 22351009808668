.singleAdElementContents {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.singleAdElementButtons {
  /* font-size: 1.25rem; */
  display: flex;
  margin: 1rem 0;
}

.singleAdElementButton {
  /* font-size: 1.25rem; */
  margin-right: 2rem;
}