.chargeRecord {
  padding: 0.5rem;
  /* margin: 0 auto;
  max-width: 40rem; */
}

.chargeRecordTitle {
  text-align: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
  font-size: 1.5rem;
}
