.userFiles {
  width: 100%;
  max-width: 40rem;
  margin: 0 auto;
}

.userFiles ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.userFilesTitle {
  font-size: 1.25rem;
  /* padding: 2rem 0 1rem 0; */
  padding-bottom: 1rem;
}

.userFilesSelectMessage {
  /* font-size: 1.25rem; */
  /* padding: 2rem 0 1rem 0; */
  padding-bottom: 1rem;
}

.userFilesList {
  display: flex;
  flex-flow: row wrap;
}

.userFilesImagesContainer {
  padding-bottom: 3rem;
}

.userFilesAdImage {
  /* width: 125px;  */
  /* width: 30px; */
  height: 100px;
  width: 100px;
  object-fit: cover;
  padding: 10px 10px;
  cursor: pointer;
  /* height:"150px", 
  marginRight: "20px", cursor:"pointer" */
}

.userFiles150x500Image {
  /* width: 125px;  */
  /* width: 30px; */
  height: 150px;
  object-fit: cover;
  padding: 10px 10px;
  cursor: pointer;
  /* height:"150px", 
  marginRight: "20px", cursor:"pointer" */
}

.userFiles600x130Image {
  width: 150px; 
  /* height: 150px; */
  object-fit: cover;
  padding: 10px 10px;
  cursor: pointer;
  /* height:"150px", 
  marginRight: "20px", cursor:"pointer" */
}


.userFilesVideoContainer {
  position: relative;
}

.userFilesVideo {
  position: absolute;

  height: 100px;
  width: 100px;
  /* object-fit: cover; */
  /* padding: 10px 10px; */
  cursor: pointer;
}

.userFilesVideoMark {
  background-color: rgba(0,0,0,0.01);
  color: gray;
  position: absolute;
  padding: 1rem;

  top: 1%;
  right: 10%;
  font-size: 2rem;
  cursor: pointer;
}

.userFilesSelectMark {
  /* background-color: white; */
  background-color: gray;
  color: white;
  /* color: red; */
  position: absolute;

  bottom: 1%;
  left: 1%;
  font-weight: bold;
  /* font-size: 2rem; */
  /* cursor: pointer; */
}

/* .userFilesShowSelected {
  padding: 2rem;
} */

.userFilesShowSelectedButton {
  position: fixed;
  top: 3rem;
  right: 5px;
  font-size: 1.1rem;
  z-index: 10;
  /* padding: 0.5rem; */
}