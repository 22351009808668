.notPageFound {
  padding: 1rem;
}

.notPageFound__container {
  text-align: center;
}

.notPageFound__textLink {
  /* margin-top: 1.5rem; */
  /* margin-bottom: 1.5rem; */
  margin: 1.5rem;
  font-size: 1.5rem;
}

.notPageFound__pageButton {
  margin-top: 1rem;
}

.notPageFound__linkButton {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.notPageFound__Loader {
  text-align: center;
}

.notPageFound__AdnetDescription {
  width: 90%;
  max-width: 40rem;
  margin: 0 auto;
}

.notPageFound__ExampleButton {
  cursor: pointer;
  padding-bottom: 1rem;
}

a {
  text-decoration: none;
}