.reuseImageContainer {
  height: 300px;
  max-height: 80vh; 
  width: 90%; 
  margin-top: 1rem;
  overflow-y: auto; 
  border: 1px solid gray;
  border-radius: 4px;
}

.reuseImageList {
  display: flex;
  flex-flow: row wrap;
}

.magesForReuse150x500Image {
  /* width: 125px;  */
  /* width: 30px; */
  height: 200px;
  object-fit: cover;
  padding: 10px 10px;
  cursor: pointer;
  /* height:"150px", 
  marginRight: "20px", cursor:"pointer" */
}

.magesForReuse600x130Image {
  width: 200px; 
  /* height: 150px; */
  object-fit: cover;
  padding: 10px 10px;
  cursor: pointer;
  /* height:"150px", 
  marginRight: "20px", cursor:"pointer" */
}

.reuseImageOpenButton {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
}