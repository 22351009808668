.feedEditLanguageTitle {
  margin-top: 2rem;
  text-transform: uppercase;
}

.feedEditLanguageContents {
  margin-top: 1rem;
}

.feedEditLanguageChangeButton {
  margin-left: 2rem;
  cursor: pointer;
}

.languageList-container {
  position: fixed;
  top: 5%;
  left: 50px;
  z-index: 200;
  background: white;
  max-height: 80%;
  overflow: auto;
}

.listElement {
  cursor: pointer;
  margin-bottom: 1rem;
}