.table {
  border: 1px solid;
  margin-bottom: 1rem;
}

.tableHead {
  padding: 0.5rem;
  /* border-bottom: solid 3px red; */
  background: aliceblue;
  /* color: black; */
  font-weight: bold;
}

.tableHeadSort {
  font-size: 0.7rem;
  cursor: pointer; 
  margin: 0 0.5rem;
}

.tableRow {
  padding: 0.5rem;
  border: solid 1px gray;
  /* background: papayawhip; */
}

.paginatorRow {
  padding: 1rem 0;
}

.paginatorRow2 {
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
}

.paginatorRow button {
  padding: 0.2rem 1.25rem;
  font-weight: bold;
}

.paginatorInput {
  padding: 0.5rem;
  width: 4rem;
}

.paginatorSelect {
  padding: 0.5rem;
  margin-left: 1rem;
}

.paginatorInput input {
  width: 5rem;
}

@media (min-width: 768px) {
  .paginatorRow2 {
    justify-content: flex-start;
  }
}