.reuseImageContainer {
  height: 300px;
  max-height: 80vh; 
  width: 90%; 
  margin-top: 1rem;
  overflow-y: auto; 
  border: 1px solid gray;
  border-radius: 4px;
}

.reuseImageList {
  display: flex;
  flex-flow: row wrap;
}

.reuseImageImage {
  width: 125px; 
  height: 125px;
  object-fit: cover;
  padding: 10px 10px;
  cursor: pointer;
}

.reuseImageVideoContainer {
  position: relative;
}

.resuseImageVideo {
  position: absolute;
}

.reuseImageVideoMark {
  background-color: rgba(0,0,0,0.01);
  color: gray;
  position: absolute;
  padding: 1rem;
  top: 0;
  right: 0;
  font-size: 1.5rem;
}

.reuseImageOpenButton {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
}