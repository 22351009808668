.feedEditVideoDuration {
  padding-top: 1rem;
}

.feedEditVideoDurationTitle {
  text-transform: uppercase;
}

.feedEditVideoDurationContent {
  padding-top: 0.5rem;
}

.feedEditVideoDurationSelect {
  padding: 0.4rem;
  font-size: 1rem;
}