.paymentSetupAddCardTitle {
  text-align: center;
  text-decoration: underline;
  font-size: 1.25rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

.subscriptionLoader {
  margin-top: 1rem;
  text-align: center;
}