.FEImageUpdateTitle {
  font-size: 1.1rem;
  cursor: pointer;
}

.FEImageUpdateUploadTitle {
  padding: 0.5rem 0;
  font-weight: bold;
}

.FEImageUpdateDelete {
  padding: 1.5rem 0;
}

.FEImageUpdateDeleteButton {
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
}

.FEImageUpdateSelectImageBody {
  padding: 1rem 0 1rem 1rem;
}

.FEImageUpdateUploadButtons {
  display: flex;
  padding: 1rem 0 2rem 0;
}