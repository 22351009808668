.pageNotificationItem {
  padding: 0.5rem;
}

.pageNotificationItemLink {
  padding-left: 0.25rem;
}

.pageNotificationItemTime {
  text-align: right;
  /* padding-top: 0.25rem; */
  font-size: 0.8rem;
}