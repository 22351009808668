.adElementItemContainer {
  margin: 1rem 0;
  /* border: 1px solid #3b0062; */
  border: 0.5px solid #999;
  border-radius: 2px;
  padding: 0.5rem;
}
.adElementItemContainer a {
  text-decoration: none;
  color: var(--color);
}

.adElementItemImagesContainer {
  display: flex;
  gap: 1rem;
  align-items: end;
}

.adElementItemImage {
  height: 75px;
  width: 75px;
  object-fit: cover;
  margin-right: 1rem;
}

.adElementItemImage150x500 {
  height: 75px;
  width: 20px;
  object-fit: cover;
  margin-right: 1rem;
}

.adElementItemImage600x130 {
  height: 20px;
  width: 100px;
  object-fit: cover;
}

.adElementItemVideo {
  height: 75px;
  object-fit: cover;
  margin-right: 1rem;
}

.adElementItemTitle {
  font-size: 1.5rem;
}

.adElementItemButtons {
  display: flex;
  justify-content: end;
  gap: 0.5rem;
}
