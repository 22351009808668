.adReport {
  width:95%;
  max-width: 40rem;
  /* padding: 1rem; */
  margin: 0 auto;
}

.adReportTitle {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}