.inputForm h2 {
  text-align: center;
  margin: 2rem
}

.inputForm section {
  margin-bottom: 1rem;
}

.inputForm ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.inputForm label {
  /* color: rgb(66, 76, 97); */
  display: block;
  /* font-weight: bold; */
  margin: 0.5rem 0;
}

.inputForm a {
  color: rgb(66, 76, 97);
}

.inputForm input,
.inputForm textarea,
.inputForm select {
  font: inherit;
  padding: 0.5rem;
  border: 1px solid rgb(152, 159, 184);
  width: 100%;
  display: block;
  border-radius: 4px;
  box-sizing: border-box;
  margin: auto;
}

.inputForm textarea {
  resize: vertical;
}

.inputForm button {
  font: inherit;
  border: 1px solid rgb(51, 67, 100);
  padding: 0.5rem 1.5rem;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1rem;
  margin-top: 0.5rem;
}

.inputForm button:hover,
button:active {
  text-decoration: none;
}

.inputForm {
  width: 90%;
  max-width: 40rem;
  margin: auto;
  padding: 0.5rem;
  background-color: rgb(249, 251, 255);
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  border-radius: 6px;
}

.btnFilled {
  background-color: rgb(51, 67, 100);
  color: white;
}

.btnEmpty {
  background-color: transparent;
  color: rgb(51, 67, 100);
}

.btnFilled:hover {
  background-color: rgb(69, 89, 129);
  border-color: rgb(69, 89, 129);
}

.btnEmpty:hover {
  background-color: rgb(208, 215, 230);
}

.messageDisplay {
  white-space: pre-wrap;
}

.inputFormButtons {
  display: flex;
}