.AutoSuggest__Element {
  display: flex;
  justify-content: flex-start;
  align-items: center
}

.AutoSuggest__ImageContainer {
  height: 2rem;
  width: 3.5rem;
  text-align: center;
  margin-right: 0.5rem;
}

.AutoSuggest__ImageElement {
  max-width: 100%;
  max-height: 100%;
}

.AutoSuggest__nameElement {
  margin-right: 0.5rem;
  /* max-height: 100%; */
}

.AutoSuggest__nameElement-name {
  font-weight: bolder;

}