.totalResult {
  display: flex;
  flex-direction: column;
}
.totalResultItem {
  margin-right: 1rem;
  font-size: 1.22rem;
}


@media (min-width: 768px) {
  .totalResult {
    flex-direction: row;
  }
}